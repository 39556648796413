@import "minima";

pre {	
  background-color: #f2f2f2;
}

code {	
  background-color: #f2f2f2;
}

.highlighter-rouge .highlight {	
  background-color: #f2f2f2;
}
